import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import BlogPostContainer from "../components/BlogPost/BlogPostContainer";

const BlogPost = (props) => {
  const { contentfulBlogPost: post } = props.data;

  return (
    <Layout vertical={true}>
      <Seo title={post.title} />
      <BlogPostContainer post={post} references={post.body.references} />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByTitle($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      featuredImage {
        gatsbyImageData
      }
      body {
        raw
        references {
          contentful_id
          gatsbyImageData
        }
      }
      slug
      publishedOn
    }
  }
`;
