import { apiUrl } from "../urls";

export const addNewsletterSubscriber = async (email: string) => {
  try {
    let res = await fetch(`${apiUrl}/blogMailing.php`, {
      method: "POST",
      body: JSON.stringify({ email }),
    });
    let parsedRes = await res.json();
    return parsedRes;
  } catch (e) {
    console.error(e);
    return null;
  }
};
