import React from "react";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import styled from "styled-components";
import { StyledTitle } from "../../styles/StyledComponents";
import { colors } from "../../styles/colors";
import dayjs from "dayjs";
import NewsletterModal from "./NewsletterModal";

const BlogPostWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 1260px;
  @media only screen and (max-width: 991px) {
    max-width: 800px;
  }

  @media only screen and (max-width: 768px) {
    max-width: unset;
    padding: 0px 10px;
  }
`;

const BlogPostTitleWrapper = styled.div`
  padding: 50px 0px;
  padding-bottom: 0px;
  @media only screen and (max-width: 478px) {
    padding: 0px;
  }
`;

const BlogPostTitleContent = styled.div`
  position: relative;
  padding: 50px 30px;

  background-color: ${colors.white};
  max-width: 760px;
  margin-top: -100px;
  border-top-right-radius: 30px;

  @media only screen and (max-width: 478px) {
    margin-top: 10px;
    padding: 10px 0px;
  }
`;

const BlogPostTitle = styled(StyledTitle)`
  font-family: "Lato";
  color: ${colors.black};
  font-weight: 700;
`;

const BlogPostSubtitle = styled(StyledTitle)`
  font-family: "Lato";
  font-size: 25px;
  margin-top: 10px;
  color: ${colors.black};
`;

const HeaderImage = styled.img`
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  border-radius: 0px;
`;

const BlogPostImage = styled.img`
  max-width: 100%;
`;

const BlogPostContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-column-gap: 15px;

  @media only screen and (max-width: 478px) {
    display: block;
  }
`;

const BlogPostContent = styled.article`
  padding: 30px 35px;
  @media only screen and (max-width: 478px) {
    padding: 0px;
  }
`;

const BlogPostContainer = ({
  post,
  references,
}: {
  post: any;
  references: any[];
}) => {
  const parsedPost = JSON.parse(post.body.raw);

  const options: Options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <BlogPostTitle>{children}</BlogPostTitle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const item = references.find(
          (ref) => ref.contentful_id === node.data.target.sys.id
        );
        return (
          <BlogPostImage
            srcSet={item.gatsbyImageData.images.sources[0].srcSet}
          />
        );
      },
    },
  };

  const components = documentToReactComponents(parsedPost, options);
  return (
    <BlogPostWrapper>
      <HeaderImage
        srcSet={post.featuredImage.gatsbyImageData.images.sources[0].srcSet}
      />
      <BlogPostTitleWrapper>
        <BlogPostTitleContent>
          <BlogPostTitle>{post.title}</BlogPostTitle>
          <BlogPostSubtitle>
            {dayjs(post.publishedOn).format("MMMM DD, YYYY")}
          </BlogPostSubtitle>
        </BlogPostTitleContent>
      </BlogPostTitleWrapper>
      <BlogPostContentWrapper>
        <BlogPostContent>{components}</BlogPostContent>
        <NewsletterModal />
      </BlogPostContentWrapper>
    </BlogPostWrapper>
  );
};

export default BlogPostContainer;
