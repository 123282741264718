import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { StyledTitle } from "../../styles/StyledComponents";
import Button from "../Button/Button";
import logo from "../../images/mdt-turtle-logo.svg";
import { useState } from "react";
import { validateEmail } from "../../util/globals";
import { useWarning } from "../../util/hooks";
import { addNewsletterSubscriber } from "../../util/data/Newsletter";

const Newsletter = styled.aside`
  position: sticky;
  top: 32px;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 32px;
  padding: 48px 28px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e7e8f4;
  border-radius: 24px;
  box-shadow: 0 6px 2px 0 ${`${colors.black}06`},
    0 10px 5px 0 ${`${colors.black}01`}, 0 16px 10px 0 ${`${colors.black}02`},
    0 24px 17px 0 ${`${colors.black}04`};
  align-self: start;

  @media only screen and (max-width: 991px) {
  }

  @media only screen and (max-width: 768px) {
  }

  @media only screen and (max-width: 478px) {
    margin: 15px 10px;
  }
`;

const NewsletterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NewsletterTitle = styled(StyledTitle)`
  font-family: "Lato";
  font-weight: bold;
  text-align: center;
  margin: 15px 0px;
  font-size: 30px;
`;

const NewsletterError = styled.p`
  font-family: "Lato";
  font-weight: bold;
  text-align: center;
  color: red;
  margin: 15px 0px;
  font-size: 15px;
`;

const NewsletterText = styled.p`
  font-family: "Lato";
  font-weight: normal;
  text-align: center;
  color: ${colors.lightBlue};
  margin: 15px 0px;
  font-size: 15px;
`;

const NewsletterButton = styled(Button)`
  min-width: unset;
  width: 100%;
  border-radius: 30px;
  padding: 20px 0px;
  margin: 0px;
  font-weight: bold;
`;

const NewsletterInput = styled.input`
  min-height: 40px;
  margin-bottom: 15px;
  padding: 10px 25px;
  border-style: solid;
  border-width: 1px;
  border-color: #e7e8f4;
  border-radius: 80px;
  font-family: "Lato";
  font-size: 20px;
  font-weight: 500;
`;

const NewsletterModal = () => {
  const [inputEmail, setInputEmail] = useState<string>("");
  const [warning, createWarning] = useWarning();
  const [status, createStatus] = useWarning();

  const submitEmail = async () => {
    // make api request to blogMailing enpoint
    if (validateEmail(inputEmail)) {
      const res = await addNewsletterSubscriber(inputEmail);
      if (!res.success) {
        if (res.reason.includes("Invalid Query")) {
          createWarning("That email is already subscribed");
        } else {
          createWarning("Something went wrong");
        }
      } else {
        createStatus(
          "Thank you for subscribing to the Debris Tracker newsletter!"
        );
      }
      setInputEmail("");
    } else {
      createWarning("Please enter a valid email");
    }
  };

  return (
    <Newsletter>
      <img src={logo} alt="" />
      <NewsletterContent>
        <NewsletterTitle>Subscribe to our Newsletter</NewsletterTitle>
        {warning && <NewsletterError>{warning}</NewsletterError>}
        {status && <NewsletterText>{status}</NewsletterText>}
        <NewsletterInput
          onChange={(e: any) => {
            setInputEmail(e.target.value);
          }}
          value={inputEmail}
          placeholder="Enter your email"
        />
        <NewsletterButton onClick={submitEmail}>Subscribe Now</NewsletterButton>
      </NewsletterContent>
    </Newsletter>
  );
};

export default NewsletterModal;
